<template>
  <div class="comment-item">
    <div class="author-thumbnail">
      <template v-if="isAvatarValid">
        <img :src="this.authorPic" alt="" @error="handleAvatarError" />
      </template>
      <template v-else>
        <div class="fallback-avatar">{{ posterInitial }}</div>
      </template>
    </div>
    <div class="comment-content">
      <div class="header">
        <a href="">
          <h3 class="header-author">{{ author }}</h3>
          <span class="header-published-time"
            ><time datetime="this.postedSince">{{ this.postedSince }}</time>
            ago</span
          >
        </a>
      </div>
      <div class="comment-text">
        <div v-if="isCollapsed">
          <p class="collapsed">{{ text }}</p>
        </div>
        <div v-else>
          <p>{{ text }}</p>
          <span style="font-weight: bold"
            ><button @click="setCollapsable">Show more</button></span
          >
        </div>
      </div>
      <div class="action-buttons">
        <div class="like-comment">
          <button>
            <img src="../../assets/img/Youtube/like-icon.png" alt="" />
            <span>{{ likeCount }}</span>
          </button>
        </div>
        <div class="dislike-comment">
          <button>
            <img src="../../assets/img/Youtube/dislike-icon.png" alt="" />
          </button>
        </div>
        <div class="reply-comment">
          <button>
            <span>Reply</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["comment"],
  data() {
    return {
      isCollapsed: true,
    };
  },
  methods: {
    setCollapsable() {
      this.isCollapsed = !this.isCollapsed;
    },
  },
  computed: {
    author() {
      return this.comment.posterName;
    },
    text() {
      return this.comment.commentText;
    },
    authorPic() {
      return this.comment.profilePic;
    },
    likeCount() {
      return this.comment.likeCount;
    },
    postedSince() {
      const postedDate = new Date(this.comment.postedSince);
      const now = new Date();
      const timeDiff = now - postedDate;
      const seconds = Math.floor(timeDiff / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      const months = Math.floor(days / 30);
      const years = Math.floor(months / 12);
      if (seconds <= 60) return `${seconds} seconds`;
      if (minutes <= 60) return `${minutes} minutes`;
      if (hours <= 24) return `${hours} hours`;
      if (days <= 30) return `${days} days`;
      if (months <= 12) return `${months} months`;
      return `${years} years`;
      // return "Years ago"
    },
    posterInitial() {
      return this.comment.posterName?.charAt(1).toUpperCase() || '?';
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/youtube-styles.scss";
.comment-item {
  font-family: $youtube-font;
  display: flex;
  .author-thumbnail {
    cursor: pointer;
    width: $youtube-video-content-avatar-width + 16px;
    img {
      width: $youtube-video-content-avatar-width;
      height: $youtube-video-content-avatar-height;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  .comment-content {
    display: flex;
    flex-direction: column;
    .header {
      height: 17px;
      a {
        display: flex;
        text-decoration: none;
        color: inherit;
      }
      .header-author {
        font-size: 15px;
        font-weight: bold;
      }
      .header-published-time {
        font-size: 12px;
        color: $videoDetails-color;
        margin-left: 5px;
        margin-top: 0px;
      }
    }
    .comment-text {
      p {
        font-size: 14px;
      }
    }
  }
  p.collapsed {
    max-height: 4.5em;
    overflow: hidden;
    margin-bottom: 0;
  }
  .action-buttons {
    height: $youtube-desktop-comment-action-buttons-height;
    display: flex;
    margin-top: 8px;
    flex-direction: row;
    button {
      border: none;
      background-color: transparent;
      height: inherit;
    }
    img {
      margin-left: -4px;
      width: 20px;
      height: 20px;
    }
    .like-comment {
      img {
        margin-top: -3px;
      }

      span {
        color: $youtube-like-counter-commnet-light;
        margin-left: 5px;
        margin-right: 8px;
      }
    }
    .reply-comment {
      margin-left: 10px;
      margin-top: -3px;
      span {
        font-size: 13px;
        font-weight: bolder;
      }
    }
  }
}
.fallback-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $videoItem-avatar-desktop-width;
  height: $videoItem-avatar-desktop-height;
  border-radius: 50%;
  background-color: #ab5d24; 
  font-size: 14px; 
  color: #ffffff; 
  font-weight: bold;
}
</style>
