<template>
  <header>
    <!-- Desktop Header -->
    <div class="header-desktop-youtube">
      <b-container fluid class="h-100">
        <b-row :class="isMobile? 'addShadow':''">
          <b-col id="Start" class="header-item">
            <button v-if="!isMobile">
              <img
                class="yt-icon"
                src="../../assets/img/Youtube/menu.png"
                alt="MENU"
              />
            </button>
            <button id="btnLogo" @click="goBackToHomepage">
              <img
                id="logo"
                src="../../assets/img/Youtube/yt_logo_rgb_light.png"
                alt="Youtube-Logo"
              />
            </button>
          </b-col>
          <b-col id="Center" class="header-item" v-if="windowWidth>668 && !isMobile" >
            <div class="search">
                <div>
                  <div class="input-div-row">
                    <input
                      id="search-container"
                      type="text"
                      value=""
                      v-model="searchWord"
                      :placeholder="$t('page.youtube.youtubeSearch')"
                    />
                  </div>
                </div>    
              <div id="searchIcon">
                <button class="sumbit-btn">
                  <img
                    src="../../assets/img/Youtube/search_icon.png"
                    alt=""
                    class="yt-icon"
                  />
                </button>
              </div>
            </div>
          </b-col>
          <b-col id="End" class="header-item" v-show="windowWidth>200">
            <button v-show="windowWidth>300 && !isMobile">
              <img
                class="yt-icon"
                src="../../assets/img/Youtube/createVideo.png"
                alt="Create Video"
              />
            </button>
            <button  v-show="windowWidth>260 && !isMobile">
              <img
                class="yt-icon"
                src="../../assets/img/Youtube/notification.png"
                alt="Notifications"
              />
            </button>
                <button v-if="isMobile" id="seachIcon-mobile">
                  <img
                    src="../../assets/img/Youtube/search_icon.png"
                    alt=""
                    class="yt-icon"
                  />
                </button>
            <button>
              <img
                class="yt-icon"
                src="../../assets/img/Youtube/userPhoto.png"
                alt="User"
              />
            </button>
          </b-col>
        </b-row>
        <b-row v-if="this.isMobile">
          <!-- <SubHeader /> -->
        </b-row>
      </b-container>
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
// import SubHeader from "./SubHeader.vue";
export default {
  props:{
    isMobile:{
      type: Boolean,
      default:false,
    }
  },
  data() {
    return {
      
      searchWord: "",
      windowWidth: window.innerWidth
    };
  },
  components:{
    // SubHeader,
  },
  computed:{
    ...mapGetters("youtube", {
      surveyStep: "getSurveyStep",
    }),
  },
  methods: {
    goBackToHomepage() {
      if(this.surveyStep==="stimulus")return;
      window.scrollTo(0,0)
      this.$store.commit("youtube/SET_VIDEO_SELECTED", "");
      this.$store.commit("youtube/SET_VIDEO_SELECTED_INDEX", -1);
      this.$store.commit("youtube/SET_SELECTED_POST", null);
    },
    onResize(){
      this.windowWidth = window.innerWidth;
    }
  },
  created() {
    this.$store.commit("utilities/SET_EMOTIVA_HEADER", false);
  },
  mounted(){
    this.$nextTick(()=>{
      window.addEventListener("resize",this.onResize);
    })
  },
  beforeDestroy(){
    window.removeEventListener("resize",this.onResize);
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/youtube-styles.scss";

.header-desktop-youtube {
  width: 100%;
  height: 60px;
  display: flex;
  background-color: #f1f1f1;
  button {
    border: none;
    background-color: transparent;
    padding: 8px;
    margin: 0px;
  }

  .yt-icon {
    height: 24px;
    width: auto;
    object-fit: fill;
  }

  #Start {
    justify-self: start;
    #logo {
      height: 20px;
    }
    #btnLogo {
      height: $header-desktop-height;
    }
    max-width: 33%;
  }
  #Center {
    justify-self: center;
    display: flex;
    padding: 8px;
    height: 64px;
    min-width: 100px;
    max-width: $searchbar-desktop-max-width;
    .search {
      input:focus {
        outline: 1px solid $header-input-focus-color;
      }
      display: flex;
      height: 40px;
      #search-container {
        height: 40px;
        width: 33vw;

        justify-content: space-between;
        flex-direction: row;
        border: 1px solid $header-border-color-light;
        border-radius: 40px 0px 0px 40px;
        position: relative;
        align-items: center;
        // margin-left: 32px;
        flex: 1;
        display: flex;
        flex-direction: row;
        padding: 0px 4px 0px 16px;
      }
      #searchIcon {
        align-self: center;
        .sumbit-btn {
          border: 1px solid $header-border-color-light;
          padding: 1px 6px;
          border-radius: 0px 40px 40px 0;
          cursor: pointer;
          height: 40px;
          width: 64px;
          background-color: $header-searchIcon-desktop-light-color;
          img{
            cursor: pointer;
          }
        }
      }
    }
  }

  #End {
    margin: 9px 0px 0px 0px;
    display: flex;
    justify-self: end;
    justify-content: flex-end;
  }
  @media screen and (max-width:668px) {
    #Start{
      max-width: 100%;
    }
    #End{
      max-width: 120px;
    }
  } 
  @media screen and (max-width:300px) {
    #End{
      max-width: 60px;
    }
  }
}
.header-item {
  max-height: $header-desktop-item-maxHeight;
}
#seachIcon-mobile{
  height: 30px;
  padding-bottom: 10px;
}
.main-row{
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  margin-bottom: 5px;
}
</style>
