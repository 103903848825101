<template>
  <div class="videoItem">
    <div>
      <div @click="setVideoSelected" class="clickable-div-button">
        <div class="thumbnail">
          <img :src="thumbnail" alt="thumbnail" />
        </div>
        <div class="videoInfo">
          <div class="videoTitle">
            <h3>{{ videoTitle }}</h3>
          </div>
          <div class="metadata">
            <div class="channelName">
              <p>{{ channelName }}</p>
            </div>
            <div class="postDate">
              <p>{{ postedSince }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  
  props: ["post"], // link
  computed: {
    ...mapGetters("youtube", {
      surveyStep: "getSurveyStep",
    }),
    postIndex() {
      return this.$vnode.key;
    },
    thumbnail() {
      return this.post.body.body.bottomRow.link.imageUrl;
    },
    videoLink() {
      return this.post.body.body.bottomRow.link.externalUrl;
    },
    videoId() {
      // youtubeId
      return this.post.body.body.bottomRow.link.text.subtitle;
    },
    videoTitle() {
      return this.post.body.body.bottomRow.link.text.title;
    },
    postedSince() {
      const postedDate = new Date(
        this.post.body.header.rightColumn.bottomRow.postedSince
      );
      const now = new Date();
      const timeDiff = now - postedDate;
      const seconds = Math.floor(timeDiff / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      const months = Math.floor(days / 30);
      const years = Math.floor(months / 12);
      if (seconds <= 60) return `${seconds} seconds ago`;
      if (minutes <= 60) return `${minutes} minutes ago`;
      if (hours <= 24) return `${hours} hours ago`;
      if (days <= 30) return `${days} days ago`;
      if (months <= 12) return `${months} months ago`;
      return `${years} years ago`;
    },
    videoViews() {
      return "TODO fix"; // youtube apicall doesnt return video views
    },
    channelName() {
      return this.post.body.header.rightColumn.bottomRow.posterName;
    },
    channelAvatar() {
      return this.post.body.header.leftColumn.imageUrl;
    },
  },
  methods: {
    setVideoSelected() {
      if(this.surveyStep==="stimulus")return;
      this.$store.commit("youtube/SET_VIDEO_SELECTED", this.videoId);
      this.$store.commit("youtube/SET_SELECTED_POST", this.post);
      this.$store.commit("youtube/SET_VIDEO_SELECTED_INDEX", this.postIndex);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/youtube-styles.scss";
a {
  text-decoration: none;
  color: inherit;
}

.videoItem {
  font-family: $videoItem-videoTitle-font-family;
  display: flex;

  .clickable-div-button {
    cursor: pointer;
    display: flex;
  }

  .thumbnail {
    width: $youtube-video-vertical-thumbnail-width;
    margin-right: 8px;
    img {
      width: inherit;
      object-fit: cover;
      border-radius: 10px;
      max-height: 100px;
    }
  }
  .videoInfo {
    margin-top: 2px;
    display: flex;
    flex-direction: column;
    min-width: 0;
    padding: 0px 24px 0px 0px;
    
    p {
      margin: 0px;
      line-height: 20px;
    }
    .videoTitle {
      margin: 0 0 4px 0;
      h3 {
        font-size: 14px;
        font-weight: 600;
        text-overflow: ellipsis;
        color: $youtube-text-primary-color;
        overflow: hidden;
        max-height: 2.5em;
      }
    }
    .metadata {
      p {
        color: $videoDetails-color;
        font-size: 12px;
      }
      display: flex;
      flex-direction: column;
      .channelName {
        margin: 0px;
        overflow: hidden;
        white-space: nowrap;
      }
      .postDate {
        margin: 0px;
      }
    }
  }
}
</style>
