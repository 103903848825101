<template>
  <div>
    <div class="yt-square-sidebarItem">
      <button>
        <div class="item">
          <div class="yt-icon">
            <img :src="ytIcon" alt="" />
          </div>
          <div class="textTitle">
            <p>{{ $t("page.youtube.leftSideBar." + this.text) }}</p>
          </div>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["icon", "title"],
  computed: {
    ytIcon() {
      return this.icon;
    },
    text() {
      return this.title;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/youtube-styles.scss";
button {
  border: none;
  background-color: transparent;
  width: inherit;
  height: inherit;
  padding: 16px 0 14px;
}

.yt-square-sidebarItem {
  width: $sideBarSquare-item-width;
  height: $sideBarSquare-item-height;
  display: flex;
  justify-content: center;
  align-items: center;
  .item {
    align-items: center;
    .yt-icon {
      display: flex;
      justify-content: center;
      padding-bottom: 8px;
      img {
        width: 24px;
        height: 24px;
      }
    }
    .textTitle {
      p {
        text-align: center;
        font-size: xx-small;
        font-family: $youtube-font;
      }
    }
  }
}
</style>
