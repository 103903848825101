<template>
  <b-col class="left-sidebar  d-lg-flex pl-2 sticky-column col-xl-2">
    <div class="SideBarItems">
      <LeftSideBarItemSquare
        v-for="(item, index) in items.squareItems"
        :key="index"
        :icon="item.itemIcon"
        :title="item.itemText"
      />
    </div>
  </b-col>
</template>

<script>
import LeftSideBarItemSquare from "./LeftSideBarItemSquare.vue";
import homeIcon from "../../assets/img/Youtube/homepage.png";
import shortsIcon from "../../assets/img/Youtube/ytShorts_light.svg";
import subscriptionsIcon from "../../assets/img/Youtube/subscriptions.png";
import libraryIcon from "../../assets/img/Youtube/library.png";
export default {
  components: {
    // LeftSideBarItem,
    LeftSideBarItemSquare,
  },
  data() {
    return {
      items: {
        lineItems: [
          {
            itemText: "",
            itemIcon: "",
          },
        ],
        squareItems: [
          {
            itemText: "home",
            itemIcon: homeIcon,
          },
          {
            itemText: "shorts",
            itemIcon: shortsIcon,
          },
          {
            itemText: "subscriptions",
            itemIcon: subscriptionsIcon,
          },
          {
            itemText: "library",
            itemIcon: libraryIcon,
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/youtube-styles.scss";

.left-sidebar {
}
</style>
