<template>
    <div class="grid-container">
      <div class="videoPlayer">
        <div id="player">
          <YoutubeVideoPlayer :video-id="videoId" />
        </div>
        <div id="metadata">
          <div id="videoTitle">{{ videoTitle }}</div>
          <div id="top-row">
            <YoutubeVideoTopRow />
          </div>
          <div id="bottom-row">
            <YoutubeVideoBottomRow />
          </div>
        </div>
      </div>
      <div class="video-list">
        <VideoListVertical />
      </div>
      <div class="comments">
        <YoutubeComments />
      </div>
    </div>
</template>

<script>
import YoutubeVideoPlayer from "./YoutubeVideoPlayer.vue";
import YoutubeVideoTopRow from "./YoutubeVideoTopRow.vue";
import YoutubeVideoBottomRow from "./YoutubeVideoBottomRow.vue";
import YoutubeComments from "./YoutubeComments.vue";
import VideoListVertical from "./VideoListVertical.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    YoutubeVideoPlayer,
    YoutubeVideoTopRow,
    YoutubeVideoBottomRow,
    YoutubeComments,
    VideoListVertical,
  },
  computed: {
    ...mapGetters("youtube", {
      post: "getSelectedPost",
      
    }),
    
    videoTitle() {
      return this.post.body.body.bottomRow.link.text.title;
    },
    videoId() {
      return this.post.body.body.bottomRow.link.text.subtitle;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/youtube-styles.scss";
.grid-container{
  display: grid;
  padding: 10px 60px 10px 60px;
  grid-template-columns: 68% 20px 27%;
  grid-template-rows: 78vh 100% 100%;
}
.grid-item{

}
#videoTitle {
  font-size: 20px;
  font-weight: 500;
  color: $youtube-text-primary-color;
}

.videoPlayer{
  grid-column: 1;
  grid-row: 1;
}
.video-list{
  grid-column: 3;
  grid-row: 1;
  width:100% ;
}
.comments{
  margin-top: 30px;
  grid-row: 2;
  grid-column: 1;
  
}
@media screen and (max-width:1024px) {
  .grid-container{
    grid-template-columns:100% 100% 100% ;
    grid-template-rows: 100% 100% 100%;
  }
  
  .video-list{
    grid-column: 1;
    grid-row: 2;
  }
  .comments{
    grid-column: 1;
    grid-row: 3;
  }
  @media screen and(max-width:500px) {
    .grid-container{
      padding: 10px 24px 10px 24px;
      
    }
  }
  @media screen and(max-width:500px) {
    .grid-container{
      padding: 10px 0px 10px 0px;
      
    }
  }
  
}
</style>
