<template>
  <div>
    <div id="videoList">
      <VideoItem
        class="videoItem-wrapper"
        v-for="(post, index) in posts"
        :key="index"
        :post="post"
        :index="index"
      />
    </div>
  </div>
</template>

<script>
import VideoItem from "./VideoItem.vue";
export default {
  name: "VideoList",
  props: ["posts"],
  components: {
    VideoItem,
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/youtube-styles.scss";

#videoList {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  padding: 0;
  margin: 0;
}
</style>
