<template>
  <div class="h-100">
    <SocialsPrompt
      v-if="showPrompt"
      :surveyStep="surveyStep"
      :surveyStepDuration="surveyStepDuration"
      @continue="nextSurveyStep"
    />
    <SocialsSpinner v-if="showSpinner" />

    <transition :name="'fade'">
      <div v-if="!showPrompt && !showSpinner" id="main">
        <HeaderYoutube class="header" :isMobile="isMobile"/>
        <b-container fluid id="youtube">
          <b-row v-if="videoSelected !== ''">
            <VideoContentYT class="content" />
          </b-row>
          <b-row v-if="videoSelected === '' && this.surveyStep === 'scroll'">
            <div>
              <LeftSidebar class="left-sidebar min-vh-100 no-gutters" />
            </div>
            <div class="content-row">
              <div class="videoList">
                <VideoList class="content" :posts="posts" />
              </div>
            </div>
          </b-row>
        </b-container>
      </div>
    </transition>
  </div>
</template>

<script>
import SocialsPrompt from "../components/SocialsPrompt.vue";
import SocialsSpinner from "../components/SocialsSpinner.vue";

import LeftSidebar from "../components/Youtube/LeftSidebar.vue";
import HeaderYoutube from "../components/Youtube/HeaderYoutube.vue";
import VideoList from "../components/Youtube/VideoList.vue";
import VideoContentYT from "../components/Youtube/VideoContentYT.vue";
import { mapActions, mapGetters } from "vuex";
// import scrollIntoView from "scroll-into-view"

export default {
  name: "Youtube",
  emits: ["finishedRecording"],
  components: {
    HeaderYoutube,
    LeftSidebar,
    VideoList,
    VideoContentYT,
    SocialsPrompt,
    SocialsSpinner,
  },
  data() {
    return {
      videoID: "",
      showSpinner: false,
      firstSurveyStepTimeout: null,
      isMobile: false,
    };
  },
  created() {
    this.$store.commit("youtube/RESET_STATE");
    this.$store.commit("stimulus/SET_STIMULUS_VIEWER", "youtube");
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      this.isMobile = true;
      console.log("Its mobile")
    }
  },
  mounted() {
    if (this.surveyStep === "scroll") this.initSurveyEntry();
  },
  computed: {
    ...mapGetters("youtube", {
      showPrompt: "getShowPrompt",
      surveyStep: "getSurveyStep",
      surveyStepDuration: "getSurveyStepDuration",
      videoSelected: "getVideoSelected",
      posts: "getPosts",
      selectedVideoPost: "getSelectedPost",
    }),
    ...mapGetters("stimulus", {
      stimulus: "getStimulus",
    }),
  },
  methods: {
    loadVideo(videoURL) {},
    ...mapActions("youtube", ["getPosts"]),
    nextSurveyStep() {
      switch (this.surveyStep) {
        case "scroll":
          this.$store.commit("youtube/SET_SHOW_PROMPT", false);
          this.showSpinner = true;
          // fetch posts while showing spinner
          this.getPosts();
          this.spinnerInterval = setInterval(() => {
            this.showSpinner = false;
            clearInterval(this.spinnerInterval);
          }, 2000);
          break;
        case "stimulus":
          console.log("Phase stimulus");
          this.$store.commit("youtube/SET_SHOW_PROMPT", false);

          break;
        case "finish":
          console.log("Phase finish");
          this.$emit("finishedRecording");
          break;
        default:
          break;
      }
    },
    initSurveyEntry() {
      window.addEventListener("scroll", this.handlePostLazyLoading);
      this.$store.dispatch("stimulus/resetStimulusImages", {
        stimulusToken: this.stimulus[0].stimulusId,
      });
    },
    scrollToAd() {
      window.scrollTo(0, 0);
    },
    secondSurveyStepEntry() {
      console.log("Second phase");
      document.body.style.overflow = "hidden";
      this.scrollToAd();
    },
    handlePostLazyLoading() {
      if (
        window.scrollY + window.innerHeight + 300 >=
        document.documentElement.scrollHeight
      ) {
        this.getPosts();
      }
    },
    resetAll() {
      if (this.scrollLoggingInterval) clearInterval(this.scrollLoggingInterval);
      if (this.firstSurveyStepTimeout)
        clearTimeout(this.firstSurveyStepTimeout);
      window.removeEventListener("scroll", this.handlePostsLazyLoading);
      document.body.style.overflow = "inherit";
    },
    goBackToHomepage() {
      window.scrollTo(0, 0);
      this.$store.commit("youtube/SET_VIDEO_SELECTED", "");
      this.$store.commit("youtube/SET_VIDEO_SELECTED_INDEX", -1);
      this.$store.commit("youtube/SET_SELECTED_POST", null);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/youtube-styles.scss";
.header {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  z-index: 1;
}
#youtube {
  margin-top: 64px;
  display: flex;

  .left-sidebar {
    width: $sideBarSquare-width;
    padding: 0px 4px 0px 4px;
    margin-right: 10px;
    flex: 1;
    position: fixed;
  }

  .content {
    flex: 1;
    margin-top: 40px;
    z-index: 0;
  }
  .content-row {
    display: flex;
    justify-content: center;
    margin-top: -18px;
  }
  .videoList {
    width: 90%;
    justify-self: center;
  }
  @media (max-width: 768px) {
    .left-sidebar {
      display: none;
    }
  }
}
</style>
