<template>
  <div id="bottom-row-inner">
    <div id="description">
      <div id="info-container-meta">
        <span class="views"
          >{{ videoViews }} {{ $t("page.youtube.views") }}
        </span>
        <span class="published">{{ postedSince }} </span>
        <span class="meta-hashtags"> </span>
      </div>
      <div id="description-expandable">
        <div v-if="isCollapsed">
          <p class="collapsed">
            {{ videoDescription }}
          </p>
          <span style="font-weight: bold"
            ><button @click="setCollapsable"></button
          ></span>
        </div>
        <div v-else>
          <p class="expanded">
            {{ videoDescription }}
          </p>
          <span style="font-weight: bold"
            ><button @click="setCollapsable">Show less</button></span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      isCollapsed: true,
      // post: null,
    };
  },
  methods: {
    setCollapsable() {
      this.isCollapsed = !this.isCollapsed;
    },
  },
  computed: {
    ...mapGetters("youtube", {
      post: "getSelectedPost",
    }),
    videoViews() {
      const views = this.post.body.footer.sharesCounter;
      const thousand = views / 1000;
      const milion = thousand / 1000;
      if (views < 1000) return `${views}`;
      if (thousand < 1000) return `${thousand.toFixed(0)}K`;
      return `${milion.toFixed(0)}M`;
    },
    videoComments() {
      return this.post.body.footer.commentsCounter;
    },
    postedSince() {
      const postedDate = new Date(
        this.post.body.header.rightColumn.bottomRow.postedSince
      );
      const now = new Date();
      const timeDiff = now - postedDate;
      const seconds = Math.floor(timeDiff / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      const months = Math.floor(days / 30);
      const years = Math.floor(months / 12);
      if (seconds <= 60) return `${seconds} seconds ago`;
      if (minutes <= 60) return `${minutes} minutes ago`;
      if (hours <= 24) return `${hours} hours ago`;
      if (days <= 30) return `${days} days ago`;
      if (months <= 12) return `${months} months ago`;
      return `${years} years ago`;
    },
    videoDescription() {
      return this.post.body.body.topRow.text;
    },
  },
  // created() {
  //   this.post = this.selectedPost;
  // },
};
</script>

<style lang="scss" scoped>
@import "../../assets/youtube-styles.scss";
button {
  border: none;
}

#bottom-row-inner {
  width: inherit;
  margin: 12px 12px 0px 0px;
}
#description {
  margin: 12px;
}
p.collapsed {
  max-height: 20em;
  overflow: hidden;
  margin-bottom: 0;
  span {
    font-weight: bold;
  }
}
p.expanded {
  span {
    font-weight: bold;
  }
}
#info-container-meta {
  span {
    font-size: 15px;
    font-weight: bold;
  }
}
</style>
