<template>
  <div id="click-overlay">
    <div id="video-ad" v-if="this.canShowAd || this.surveyStep === 'stimulus'">
      <video
        autoplay
        playsinline="true"
        ref="adVideoPlayer"
        :src="this.adSrc"
        @ended="handleEnded"
        @playing="handlePlaying"
        @pause="handlepause"
        @timeupdate="updateCurrentTime"
      ></video>
      <CustomVideoControls
        v-if="this.surveyStep !== 'stimulus'"
        :video-mute="false"
        :video-playing="isAdPlaying"
        :can-skip-ad="canSkipAd"
        :countdown="this.coutdown"
        @adPauseUnpause="adPlayPause"
        @skipAd="skipAd"
        class="custom-video-controls"
      />
    </div>

    <div
      id="player"
      v-show="!this.canShowAd && this.surveyStep !== 'stimulus'"
      @click="this.ytPlayPause"
    >
      <iframe
        class="unclickable"
        ref="youtubePlayer"
        width="100%"
        height="100%"
        :src="this.videoSrc"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; enablejsapi=1; web-share"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>
<script>
import CustomVideoControls from "../Youtube/CustomVideoControls.vue";
import { mapGetters,mapActions } from "vuex";

export default {
  props: ["VideoId", "index"],
  emits: [
    "startRecording",
    "stopRecording",
    "finishedRecording",
    "nextSurveyStep",
  ],
  components: {
    CustomVideoControls,
  },
  data() {
    return {
      adPlaying: true,
      showAd: true,
      selectedPlaying: false,
      coutdown: 5,
      skipAble: false,
      initialTimestamp: Date.now(),
      videoCurrentTime: 0,
    };
  },
  mounted() {
    if (this.surveyStep === "scroll") this.firstSurveyStepEntry();
    if (this.surveyStep === "stimulus") this.secondSurveyStepEntry();
  },
  computed: {
    ...mapGetters("youtube", {
      videoSelected: "getVideoSelected",
      surveyDuration: "getSurveyStepDuration",
    }),
    ...mapGetters("stimulus", {
      stimulus: "getStimulus",
    }),
    ...mapGetters("youtube", {
      showPrompt: "getShowPrompt",
      surveyStep: "getSurveyStep",
      surveyStepDuration: "getSurveyStepDuration",
      videoSelected: "getVideoSelected",
      posts: "getPosts",
      selectedVideoPost: "getSelectedPost",
    }),
    videoSrc() {
      const src = `https://www.youtube.com/embed/`;
      if (this.videoSelected)
        return src + this.videoSelected + "?enablejsapi=1";
      return src + this.VideoId + "?enablejsapi=1";
    },
    canShowAd() {
      return this.showAd;
    },
    isAdPlaying() {
      return this.adPlaying;
    },
    canSkipAd() {
      return this.skipAble;
    },
    ytVideoPlaying() {
      return this.selectedPlaying;
    },
    currentTime() {
      return Math.round(this.videoCurrentTime);
    },
    adSrc() {
      return this.stimulus[0].src;
    },
  },
  methods: {
    ...mapActions("stimulus", [
      "resetStimulusImages",
      "setSocialsFirstSurveyStepImgCount",
    ]),
    firstSurveyStepEntry() {
      const timer = setInterval(() => {
        if (this.coutdown > 0 && this.adPlaying) {
          this.coutdown--;
        } else if (this.coutdown <= 0) {
          clearInterval(timer);
          this.skipAble = true;
        }
      }, 1000);
      setTimeout(() => {
        // this.$parent.$parent.$emit("finishedRecording");
        this.$store.commit("youtube/SET_SURVEY_STEP", "stimulus");
        this.stopRecording();
        this.$store.commit("youtube/SET_SHOW_PROMPT", true);
      }, this.surveyDuration * 1000);
    },
    secondSurveyStepEntry() {
      this.setSocialsFirstSurveyStepImgCount();
      document.body.style.overflow = "hidden";
      window.scrollTo(0, 0);
    },
    handleEnded(){
      if(this.surveyStep==="scroll")return this.skipAd()
      this.$store.commit("youtube/SET_SURVEY_STEP","finish");
      this.$store.commit("youtube/SET_SHOW_PROMPT",true);
    },
    fisnishRecording() {
      this.$parent.$parent.$parent.$emit("finishedRecording");
    },
    updateCurrentTime(event) {
      const video = event.target;
      this.videoCurrentTime = video.currentTime;
    },
    changeAdStatus() {
      this.adPlaying = !this.adPlaying;
    },
    thumbnail() {
      return this.videoSelected.body.body.bottomRow.link.imageUrl;
    },
    hideAd() {
      this.showAd = false;
    },
    showAd_setStatus(status) {
      this.showAd = status;
    },
    adPlayPause() {
      if (this.adPlaying) {
        this.$refs.adVideoPlayer.pause();
      } else {
        this.$refs.adVideoPlayer.play();
      }
      this.changeAdStatus();
    },
    skipAd() {
      this.$refs.adVideoPlayer.pause();
      this.adPlaying = false;
      this.hideAd();
      this.stopRecording();
    },
    setSelectedPlaying(state) {
      this.selectedPlaying = state;
    },
    ytPlayPause() {
      const player = this.$refs.youtubePlayer;
      if (player) {
        if (!this.ytVideoPlaying) {
          player.contentWindow.postMessage(
            '{"event":"command","func":"playVideo","args":""}',
            "*"
          );
        } else {
          player.contentWindow.postMessage(
            '{"event":"command","func":"pauseVideo","args":""}',
            "*"
          );
        }
      }
      this.setSelectedPlaying(!this.ytVideoPlaying);
    },
    startRecording({
      position,
      replay = 1,
      timestamp = Date.now() - this.initialTimestamp,
      phase = this.surveyStep === "scroll" ? 1 : 2,
    }) {
      this.adPlaying = true;
      this.$parent.$parent.$emit("startRecording", {
        stimulus: this.stimulus[0],
        position, // senconds
        replay,
        timestamp,
        phase,
      });
    },
    stopRecording() {
      this.adPlaying = false;
      this.$parent.$parent.$emit("stopRecording", {
        stimulus: this.stimulus[0],
      });
    },
    handlePlaying() {
      this.startRecording({
        position: this.currentTime,
      });
    },
    handlepause() {
      this.stopRecording();
    },
    videoStop() {
      this.adPlaying = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/youtube-styles.scss";
#click-overlay {
  position: relative;
  max-height: 720px;
  height: 100%;
  width: 100%;
  padding-bottom: 56.25%;
}
#player {
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
  position: absolute;
}
#video-ad {
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
  position: absolute;
  video {
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.custom-video-controls {
  position: absolute;
  top: 5px;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s;
}

#video-ad:hover .custom-video-controls {
  opacity: 1;
}
.unclickable {
  pointer-events: none;
}
</style>
