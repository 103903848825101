<template>
  <div class="videolist-vertical">
    <VideoListVerticalItem
      v-for="(post, index) in displayedPosts12"
      :key="index"
      :post="post"
      class="video-item-content"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VideoListVerticalItem from "./VideoListVerticalItem.vue";
export default {
  components: {
    VideoListVerticalItem,
  },
  computed: {
    ...mapGetters("youtube", {
      posts: "getPosts",
    }),
    displayedPosts12(){
      return this.posts.slice(0,12);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/youtube-styles.scss";
.videolist-vertical {
  display: flex;
  width: 100%;
  flex-direction: column;
  .video-item-content {
    margin-bottom: 8px;
  }
}
</style>
