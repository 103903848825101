<template>
  <div class="custom-video-controls-ad">
    <div class="skipAd-btn">
      <button v-if="this.CanSkip" @click="$emit('skipAd')">
        <span>{{ $t("page.youtube.skipAd") }}</span>
      </button>
      <button v-if="this.countdown > 0">
        {{ $t("page.youtube.skipAdIn") }} {{ this.countdown }}
      </button>
    </div>
    <div class="top-row">
      <div id="brand">
        <a href=""> </a>
      </div>
      <div id="share-btn-video-ad">
        <button></button>
      </div>
    </div>
    <div class="bottom-row">
      <div class="bottom-left">
        <button
          class="bottom-btn"
          id="pause"
          v-if="isVideoPlaying"
          @click="$emit('adPauseUnpause')"
        >
          <img src="../../assets/img/Youtube/pause-icon.svg" alt="" />
        </button>
        <button class="bottom-btn" id="play" v-else>
          <img
            src="../../assets/img/Youtube/play-btn-icon.svg"
            alt=""
            @click="$emit('adPauseUnpause')"
          />
        </button>
        <button class="bottom-btn" id="next-video">
          <img src="../../assets/img/Youtube/nextVideo.svg" alt="" />
        </button>
        <button class="bottom-btn" id="volume" v-if="!isMute">
          <img src="../../assets/img/Youtube/volume-filled-icon.svg" alt="" />
        </button>
        <button class="bottom-btn" id="volume-mute" v-else>
          <img src="../../assets/img/Youtube/mute.svg" alt="" />
        </button>
      </div>
      <div class="bottom-right">
        <button class="bottom-btn" id="subtitles-btn">
          <img src="../../assets/img/Youtube/subtitle-btn-icon.svg" alt="" />
        </button>
        <button class="bottom-btn" id="settings">
          <img src="../../assets/img/Youtube/settings.svg" alt="" />
        </button>
        <!-- <button class="bottom-btn" id="small-picture"><img src="../../assets/img/Youtube" alt=""></button> -->
        <button class="bottom-btn" id="wide-screen">
          <img src="../../assets/img/Youtube/widescreen.svg" alt="" />
        </button>
        <!-- <button class="bottom-btn" id="remote"><img src="../../assets/img/Youtube/re" alt=""></button> -->
        <button class="bottom-btn" id="full-screen">
          <img src="../../assets/img/Youtube/full-screen-icon.svg" alt="" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["videoPlaying", "videoMute", "canSkipAd", "countdown"],
  computed: {
    isVideoPlaying() {
      return this.videoPlaying;
    },
    isMute() {
      return this.videoMute;
    },
    CanSkip() {
      return this.canSkipAd;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/youtube-styles.scss";

#wide-screen {
  rotate: 90;
}
.custom-video-controls-ad {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: inherit;
  width: inherit;
}
.bottom-btn {
  border: none;
  background-color: transparent;
  width: $youtube-desktop-video-contro-icon-width;
  height: $youtube-desktop-video-contro-icon-height;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    object-fit: fill;
    width: $youtube-desktop-video-contro-icon-width - 3px;
    height: $youtube-desktop-video-contro-icon-height - 3px;
  }
}
.bottom-btn {
  width: $youtube-desktop-video-contro-icon-width;
  height: $youtube-desktop-video-contro-icon-height;
  padding: 0 2px;
  margin: 10px;
}
#next-video{
  img {
    object-fit: fill;
    width: $youtube-desktop-video-contro-icon-width - 5px;
    height: $youtube-desktop-video-contro-icon-height - 5px;
  }
}
.top-row {
  display: flex;
  #brand {
    display: flex;
    justify-self: start;
  }
  #share-btn-video-ad {
    display: flex;
    justify-self: end;
  }
}
.bottom-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 5px;
  // background-color: $youtube-video-control-color;
  background-color: rgba(0, 0, 0, 0.4);
  .bottom-left {
    display: flex;
    justify-self: start;
  }
  .bottom-right {
    display: flex;
    justify-self: end;
  }
}
.skipAd-btn {
  position: absolute;
  bottom: 75px;
  height: $youtube-desktop-skipAd-btn-height;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: $youtube-desktop-skipAd-btn-height;
    width: auto;
    border: none;
    background-color: rgba(0, 0, 0, 0.4);
    color: #f1f1f1;
  }
}
</style>
