<template>
  <div>
    <div id="comments-header">
      <div id="header">
        <div class="comment-number">
          <span>{{ totalComments }} {{ $t("page.youtube.comments") }}</span>
        </div>
        <div>
          <button>
            <img src="../../assets/img/Youtube/SortBy_youtube.png" alt="" />
            <span>{{ $t("page.youtube.sortBy") }}</span>
          </button>
        </div>
      </div>
    </div>
    <div id="content">
      <YoutubeCommentItem
        v-for="(comment, index) in comments"
        :comment="comment"
        :key="index"
        class="content-comment"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import YoutubeCommentItem from "./YoutubeCommentItem.vue";
export default {
  components: {
    YoutubeCommentItem,
  },
  computed: {
    ...mapGetters("youtube", {
      selectedVideoPost: "getSelectedPost",
    }),

    comments() {
      return this.selectedVideoPost.body.body.bottomRow.link.comments;
    },
    totalComments() {
      return this.selectedVideoPost.body.footer.commentsCounter;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/youtube-styles.scss";
#comments-header {
  #header {
    button {
      border: none;
      display: flex;
      align-items: flex-start;
      margin-left: 24px;
    }
    display: flex;
    flex-direction: row;
    img {
      width: 24px;
      height: 24px;
    }
  }
}
#content {
  margin-top: 10px;
  .content-comment {
    margin-bottom: 15px;
  }
}
</style>
